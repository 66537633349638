<template>
  <div
    ref="headCellRef"
    class="expenses__head-cell"
    :class="{
      'expenses__head-cell--focused':
        getFocusedCellPosition.numberOfColumn === numberOfColumn &&
        numberOfColumn !== 0 &&
        numberOfColumn !== 1 &&
        isEditableInterval &&
        !getReadonly,
      'expenses__head-cell--today': isToday(getDate(numberOfColumn).date),
      'expenses__head-cell--sticky': isItSumCell || isItEmptyCell,
      'expenses__head-cell--sum': isItSumCell,
      'expenses__head-cell--with-no-border': numberOfColumn === 0,
    }"
    :data-col-number="numberOfColumn"
    :style="{
      left: (isItSumCell || isItEmptyCell) && getLeftStickyValue,
    }"
    @click="changeFocusHeadCellNumber(numberOfColumn)"
  >
    <template v-if="!isItSumCell && !isItEmptyCell">
      <span>{{ getDate(numberOfColumn).day }}</span>
      {{ getWeekDay(numberOfColumn) }}
    </template>
    <template v-else-if="isTimerActive">
      <span class="expenses__head-cell-timer">
        <Button theme="icon" @click="timerStopHandler">
          <StopIcon />
        </Button>
        <div class="expenses__head-cell-timer-info">
          <span>{{ formattedTimer(timer.timerDuration) }}</span>
          <Tooltip
            :tooltipData="getActiveTaskName"
            :tooltip-background="'#263238'"
            :classNames="{ 'expenses__head-cell-timer-title': true }"
          />
        </div>
      </span>
    </template>
    <template v-else>
      {{ getReadonly && numberOfColumn === 1 ? "Σ" : "" }}
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import StopIcon from "@/assets/img/stop-icon.svg";
import Tooltip from "./TooltipBody";
import dayjs from "dayjs";
import Button from "@/components/Button";

export default {
  name: "ExpensesHeadCell",
  components: { StopIcon, Tooltip, Button },
  props: {
    numberOfColumn: {
      type: Number,
    },
    isItSumCell: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isItEmptyCell: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isTimerCell: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      zeroColumnLeftIndent: 180,
      sumColumnLeftIndent: 480,
    };
  },
  methods: {
    ...mapMutations({
      setFocusedCellPosition: "expensesStore/setFocusedCellPosition",
      setIsTimerActive: "expensesStore/setIsTimerActive",
    }),
    isToday(columnDate) {
      return (
        columnDate?.format("YYYY-MM-DD") === this.getMyDate.format("YYYY-MM-DD")
      );
    },
    getDate(n) {
      if (n < 2) {
        return { date: null, day: null };
      }
      const date = dayjs(this.getSelectedInterval.since).add(n - 2, "day");
      const day = date.date();
      return { date, day };
    },
    getWeekDay(n) {
      const days = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
      const dayOfWeek = dayjs(this.getSelectedInterval.since, "YYYY-MM-DD")
        .add(n - 1, "day")
        .isoWeekday();
      return days[dayOfWeek - 1];
    },
    changeFocusHeadCellNumber(numberOfColumn) {
      if (!this.getReadonly && this.isEditableInterval) {
        const cellRef = this.$refs.headCellRef;
        this.setFocusedCellPosition({
          cellRef,
          numberOfColumn: numberOfColumn,
          isVisionOn: true,
          taskId: 0,
        });
      }
    },
    timerStopHandler() {
      this.setIsTimerActive(false);
    },
    formattedTimer(duration) {
      if (this.isTimerCell) {
        const hours = Math.floor(duration / 3600)
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((duration % 3600) / 60)
          .toString()
          .padStart(2, "0");
        const seconds = (duration % 60).toString().padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
      }
      return "";
    },
  },
  computed: {
    ...mapState({
      timer: (state) => state.expensesStore.timer,
      isEditableInterval: (state) => state.expensesStore.isEditableInterval,
      firstColumnWidth: (state) => state.expensesStore.firstColumnWidth,
      user: (state) => state.userStore.me,
      readonly: (state) => state.expensesStore.readonly,
    }),
    ...mapGetters({
      getReadonly: "expensesStore/getReadonly",
      getMyDate: "userStore/getMyDate",
      getSelectedInterval: "expensesStore/getSelectedInterval",
      getFocusedCellPosition: "expensesStore/getFocusedCellPosition",
    }),
    isTimerActive() {
      return this.isTimerCell && this.timer.userId === this.user.user_id;
    },
    leftIndent() {
      return this.firstColumnWidth + this.zeroColumnLeftIndent;
    },
    getActiveTaskName: function () {
      if (this.isTimerCell) {
        return this.timer.taskName;
      }
      return "";
    },
    getLeftStickyValue() {
      return this.numberOfColumn === 0
        ? `${this.zeroColumnLeftIndent}px`
        : this.numberOfColumn === 1 && !this.readonly
        ? `${this.leftIndent}px`
        : this.numberOfColumn === 1
        ? `${this.sumColumnLeftIndent}px`
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.expenses__head-cell {
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin: 0.5px;
  position: relative;

  &:after {
    background-color: #ffffff;
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    right: -1px;
    top: 0;
    display: block;
  }

  &.expenses__head-cell--today:after,
  &.expenses__head-cell--focused:after {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    display: block;
  }

  &.expenses__head-cell--today {
    border-color: #1981c9;
    background-color: #1981c9;
    position: relative;
  }

  &.expenses__head-cell--focused {
    border-color: #6fa8dc;
    background-color: #6fa8dc;
    position: relative;
  }

  &.expenses__head-cell--today:before,
  &.expenses__head-cell--today:after {
    background-color: #1981c9;
  }

  &.expenses__head-cell--today:before,
  &.expenses__head-cell--focused:before {
    right: -1px;
  }

  &.expenses__head-cell--today:after,
  &.expenses__head-cell--focused:after {
    left: -1px;
  }

  &.expenses__head-cell--focused:before,
  &.expenses__head-cell--focused:after {
    background-color: #6fa8dc;
  }
}

.expenses__head-cell--sticky {
  position: sticky;
  z-index: 5;
  background-color: var(--primary-bg-color);
}

.expenses__head-cell--with-no-border:before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  left: -1px;
  top: 0;
  display: block;
  background-color: var(--primary-bg-color);
}

.expenses__head-cell--with-no-border:after {
  background-color: var(--primary-bg-color);
}

.expenses__head-cell--sum {
  flex-direction: column-reverse;
}

.expenses__head-cell-timer {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.expenses__head-cell-timer-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  white-space: pre;
  overflow: hidden;
  width: 100%;
  text-align: left;
  margin-left: 10px;
}

.expenses__head-cell-timer-title {
  margin-left: -4px;
}
</style>
