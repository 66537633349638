var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"headCellRef",staticClass:"expenses__head-cell",class:{
    'expenses__head-cell--focused':
      _vm.getFocusedCellPosition.numberOfColumn === _vm.numberOfColumn &&
      _vm.numberOfColumn !== 0 &&
      _vm.numberOfColumn !== 1 &&
      _vm.isEditableInterval &&
      !_vm.getReadonly,
    'expenses__head-cell--today': _vm.isToday(_vm.getDate(_vm.numberOfColumn).date),
    'expenses__head-cell--sticky': _vm.isItSumCell || _vm.isItEmptyCell,
    'expenses__head-cell--sum': _vm.isItSumCell,
    'expenses__head-cell--with-no-border': _vm.numberOfColumn === 0,
  },style:({
    left: (_vm.isItSumCell || _vm.isItEmptyCell) && _vm.getLeftStickyValue,
  }),attrs:{"data-col-number":_vm.numberOfColumn},on:{"click":function($event){return _vm.changeFocusHeadCellNumber(_vm.numberOfColumn)}}},[(!_vm.isItSumCell && !_vm.isItEmptyCell)?[_c('span',[_vm._v(_vm._s(_vm.getDate(_vm.numberOfColumn).day))]),_vm._v(" "+_vm._s(_vm.getWeekDay(_vm.numberOfColumn))+" ")]:(_vm.isTimerActive)?[_c('span',{staticClass:"expenses__head-cell-timer"},[_c('Button',{attrs:{"theme":"icon"},on:{"click":_vm.timerStopHandler}},[_c('StopIcon')],1),_c('div',{staticClass:"expenses__head-cell-timer-info"},[_c('span',[_vm._v(_vm._s(_vm.formattedTimer(_vm.timer.timerDuration)))]),_c('Tooltip',{attrs:{"tooltipData":_vm.getActiveTaskName,"tooltip-background":'#263238',"classNames":{ 'expenses__head-cell-timer-title': true }}})],1)],1)]:[_vm._v(" "+_vm._s(_vm.getReadonly && _vm.numberOfColumn === 1 ? "Σ" : "")+" ")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }