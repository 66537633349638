<template>
  <div class="sticky-header">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "StickyHeader",
};
</script>

<style lang="scss" scoped>
.sticky-header {
  position: sticky;
  top: 0px;
  z-index: 10;
}
</style>
