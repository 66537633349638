<template>
  <IntervalSelect
    :lastPlanningDate="lastPlanningDate"
    :firstPlanningDate="firstPlanningDate"
    :availableModes="modes"
    :getIntervalAtInit="true"
    storageKey="_interval-mode-expenses"
    @getInterval="getInterval"
  />
</template>

<script>
import IntervalSelect from "@/components/ui/IntervalSelect.vue";

export default {
  name: "ExpensesIntervalSelect",
  components: {
    IntervalSelect,
  },
  props: {
    lastPlanningDate: {
      type: String,
    },
    firstPlanningDate: {
      type: String,
    },
  },
  data() {
    return {
      modes: [
        { label: "Месяц", id: "month" },
      ],
    };
  },
  methods: {
    getInterval(data) {
      this.$emit("getInterval", data);
    },
  },
};
</script>
